import { Injectable } from '@angular/core';
import { RequestGenericoService } from './request/request-generico.service';
import { Saldo } from '../models/saldo';
import { HistoricoSaldo } from '../models/historico-saldo';
import { Observable } from 'rxjs';
import { Cliente } from '../models/cliente';
import { responseRecarga } from '../models/recarga';


@Injectable({
  providedIn: 'root',
})
export class SaldoService {
  private apiUrl = 'consumo';


  constructor(private request: RequestGenericoService) {}

  getSaldo(): Observable<Saldo> {
    return this.request.GET(this.apiUrl);
  }

  getHistoricosSaldoRange(range: any): Observable<HistoricoSaldo[]> {
    return this.request.POST(this.apiUrl+'/historicosaldo/token', range);
  }

  recargaSaldo(corpoRecarga: any): Observable<responseRecarga> {
    return this.request.POST(this.apiUrl+'/adicionarsaldo', corpoRecarga);
  }

  atualizaSaldoCliente(): Cliente {
    const cliente: Cliente = JSON.parse(localStorage.getItem('cliente')!);
    this.getSaldo().subscribe({
      next: (resultado) => {
        cliente.saldo = resultado.saldo
        localStorage.setItem('cliente', JSON.stringify(cliente))
      }, complete: () => {
        return cliente;
      }
    });
    return cliente;
  }
}
